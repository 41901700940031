import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`MineSQL`}</h1>
    <h4>{`Cloud Application Platform for Java / Javascript / PHP`}</h4>
    <p style={{
      "lineHeight": "1",
      "fontSize": "1em"
    }}>Rapid prototyping and application bootstrapping. Build Web Application <b>faster</b> with <b>Ext JS</b> component, object modelling with <b>ORMLite</b></p>
    <p style={{
      "lineHeight": "1",
      "fontSize": "1em"
    }}>Connect to <b>MySQL, Oracle, PostgresSQL, IBM DB2</b> or use the embedded <b>H2 Database Engine</b></p>
    <p style={{
      "lineHeight": "1",
      "fontSize": "1em"
    }}>Export your data to <b>PDF</b> or <b>Excel</b>, bulk data upload and download with <b>CSV</b>Schedule periodic task using the <b>Quartz API</b></p>
    <p style={{
      "lineHeight": "1",
      "fontSize": "1em"
    }}>Serve your page with <b>embedded Tomcat Web Server</b> or run with popular <b>NGinx or Varnish</b>. Create Seo-Friendly URLs with <b>URL Rewriting</b></p>
    <p style={{
      "lineHeight": "1",
      "fontSize": "1em"
    }}>Manage your process with <b>Node Foreman</b> and <b>PM2</b></p>
    <p style={{
      "lineHeight": "1",
      "fontSize": "1em"
    }}>Supports for <b>database clustering</b> and <b>high availability</b> with <b>Transport Layer Security</b></p>
    <p style={{
      "lineHeight": "1",
      "fontSize": "1em"
    }}>Run your <b>local server</b> deploy into the <b>Cloud</b>, scale your application on-demand with <b>Docker</b> and <b>Kubernetes</b></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      