import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Google Cloud Platform" link="https://demo.minesql.com/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Desktop App Demo
    </ProjectCard>
    <ProjectCard title="Linode" link="https://demo.minesql.com/blog/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Blog Demo
    </ProjectCard>
    <ProjectCard title="Amazon Web Service" link="https://demo.minesql.com/login.html" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Personal Web App
    </ProjectCard>
    <ProjectCard title="Microsoft Azure" link="https://ws.touringdigital.it/tci/application.wadl" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Web Service - SOAP
    </ProjectCard>
    <ProjectCard title="IBM Cloud" link="https://prod-it.eu-de.mybluemix.net/psoriasi/conversation/get-token/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Web Servie -REST API
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      